import { IEvent, IMainEvent } from "../types/Event";
import names from "../events/names.js";

export const normalizeEvent = (data: IMainEvent) =>
  <IEvent>{
    ...data,
    name: names.find((type) => type.key === data.msg_type)?.name || "Unknown",
    type: data.msg_type,
    position: {
      lat: data.coordinates && data.coordinates[0],
      lng: data.coordinates && data.coordinates[1],
    },
    temp_threshold: data.temp_threshold || "0-50"
  };

export const revertEvent = (data: IEvent) => {
  const { name, type, position, date, address, key, device, ...response } =
    data;
  return response;
};
