import astraIcon from "../images/astra.svg";
import assetTracking from "../images/asset_tracking.svg";
import user from "../images/user.svg";
import {ReactComponent as PerformanceIcon} from "../images/performance.svg";
import {AWSIoTProvider, PubSub} from "@aws-amplify/pubsub";
import {AstraAwsConfig} from "../AstraConfig";
import {useEffect, useState} from "react";
import {MessageStats} from "../types/MessageStats";

const TOPIC = "ui/stats";

const sleep = async (time: number) => {
  PubSub.removePluggable("AWSIoTProvider");
  PubSub.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: AstraAwsConfig.REGION,
      aws_pubsub_endpoint:
      AstraAwsConfig.AWS_IOT_WS_ENDPOINT,
    })
  );
  return setTimeout(() => {
    return null;
  }, time);
};

const Header = () => {

  const [isSubOpen, setIsSubOpen] = useState<boolean>(true);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [messageSize, setMessageSize] = useState<number>(0);

  const resubscribe = async () => {
    sleep(5000);
    setIsSubOpen(false);
  };

  const emitEvent = (e: MessageStats | null) => {
    if (e) {
      setMessageCount((prevState) => prevState + 1);
      setMessageSize((prevState) => prevState + e.size);
    }
  };

  const trafficEconomy = (messageCount: number) => {
    return 0.75 * messageCount
  }

  const batteryEconomy = (messageCount: number, messageSize: number) => {
    if (messageSize === 0 && messageCount === 0) return 0;
    return 100 - ((25 * messageCount + messageSize) / (750 * messageCount + messageSize)) * 100
  }

  useEffect(() => {
    let sub = PubSub.subscribe(TOPIC).subscribe({
      next: (data) => {
        const value = data.value;
        emitEvent((value as any).payload ? (value as any).payload.value : value);
      },
      error: (error) => {
        console.error(error);
        if (window.navigator.onLine) resubscribe();
      },
      complete: () => console.log("Done"),
    });
    return () => {
      sub.unsubscribe();
    };
  }, [window.navigator.onLine, isSubOpen]);

  return (
    <div className="header-component">
      <div className="first-part">
        <PerformanceIcon className="icon"/>
        <div className="info">
          <p className="header">{trafficEconomy(messageCount).toFixed(1)} Kilobytes / {batteryEconomy(messageCount, messageSize).toFixed(1)}% battery</p>
          <p
            className="content">saved by CoAP vs MQTT</p>
        </div>
      </div>
      <div className="second-part">
        <img src={astraIcon} alt="Astra asset inteligence"/>
        <img src={assetTracking} alt="Asset Tracking"/>
      </div>
      <div className="third-part">
        <img src={user} alt="User"/>
      </div>
    </div>
  );
};

export default Header;
