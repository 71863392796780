import route1 from "./config/route-3.json";
import route2 from "./config/route-4.json";

export class AstraAwsConfig {
  static readonly AWS_IOT_WS_ENDPOINT = 'wss://a1fr79kqcc2q4h-ats.iot.eu-central-1.amazonaws.com/mqtt';
  static readonly REGION = 'eu-central-1';
  static readonly MAPS = {
    default: "demo-astra2024-default",
    astra3d: "demo-astra2024-3d",
  };
  static readonly LOCATION_SEARCH_INDEX = "demo-astra2024-default";
}

export class AstraDeviceConfig {
  static readonly DEVICES_TRACKING = [
    {
      id: 1,
      name: "SenseCap T1000-A",
      topic: "ui/device_1",
      isMock: true,
      center: {
        lat: 49.452934,
        lng: 11.078034
      },
      route: route1,
    },
    {
      id: 2,
      name: "WM1110 Dev Board",
      topic: "ui/device_2",
      isMock: true,
      center: {
        lat: 49.445427686083875,
        lng: 11.086051756781792
      },
      route: route2,
    }
  ]
  static readonly DEVICES_METRICS = [
    {
      id: 3,
      name: "THINGY91 #1",
      topic: "astra/device_3",
      measurements: {
        humidity: {
          title: 'Humidity',
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/G1btqkgkK/device-3?orgId=1&theme=light&panelId=28&from=now-1h&to=now&refresh=10s"
        },
        temperature: {
          title: "Temperature",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/G1btqkgkK/device-3?orgId=1&theme=light&panelId=6&from=now-1h&to=now&refresh=10s"
        },
        airPressure: {
          title: "Air Pressure",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/G1btqkgkK/device-3?orgId=1&theme=light&panelId=26&from=now-1h&to=now&refresh=10s"
        },
        airQuality: {
          title: "Air Quality",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/G1btqkgkK/device-3?orgId=1&theme=light&panelId=29&from=now-1h&to=now&refresh=10s"
        },
        colorLight: {
          title: "Color / Light",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/G1btqkgkK/device-3?orgId=1&theme=light&panelId=24&refresh=10s"
        },
        accelerometer: {
          title: "Accelerometer",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/G1btqkgkK/device-3?orgId=1&theme=light&panelId=30&refresh=10s"
        },
        logs: {
          title: "Logs",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/G1btqkgkK/device-3?orgId=1&theme=light&panelId=31&refresh=1s"
        }
      }
    },
    {
      id: 4,
      name: "THINGY91 #2",
      topic: "astra/device_4",
      measurements: {
        humidity: {
          title: 'Humidity',
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/bmfQq9JVk/device-4?orgId=1&theme=light&panelId=28&from=now-1h&to=now&refresh=10s"
        },
        temperature: {
          title: "Temperature",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/bmfQq9JVk/device-4?orgId=1&theme=light&panelId=6&from=now-1h&to=now&refresh=10s"
        },
        airPressure: {
          title: "Air Pressure",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/bmfQq9JVk/device-4?orgId=1&theme=light&panelId=26&from=now-1h&to=now&refresh=10s"
        },
        airQuality: {
          title: "Air Quality",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/bmfQq9JVk/device-4?orgId=1&theme=light&panelId=29&from=now-1h&to=now&refresh=10s"
        },
        colorLight: {
          title: "Color / Light",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/bmfQq9JVk/device-4?orgId=1&theme=light&panelId=24&refresh=10s"
        },
        accelerometer: {
          title: "Accelerometer",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/bmfQq9JVk/device-4?orgId=1&theme=light&panelId=30&refresh=10s"
        },
        logs: {
          title: "Logs",
          src: "https://grafana.astra-2024.klika-tech.com/d-solo/bmfQq9JVk/device-4?orgId=1&theme=light&panelId=31&refresh=1s"
        }
      }
    }
  ]
}