export default [
    "acc_crash",
    "acc_flip",
    "acc_shock",
    "batt_low",
    "h_high",
    "h_low",
    "h_surge",
    "l_thr",
    "rssi_low",
    "sos",
    "t_high",
    "t_low",
    "t_surge",
    "t_thr"
]