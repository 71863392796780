import {useReducer, useState} from "react";
import gridIcon from "../images/grid.svg";
import {setViewGrid} from "../store/actions";
import Context from "../store/context";
import {initialState, reducer} from "../store/reducers";
import {AstraDeviceConfig} from "../AstraConfig";
import Device from "./Device";
import Popup from "./Popup";
import DeviceMetrics from "./DeviceMetrics";
import footer from "../images/footer.svg";

const Main = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {view} = state;
  const [display, setDisplay] = useState<string>("grid");

  return (
    <Context.Provider value={{state, dispatch}}>
      <>
        <Popup/>
        <div className={`main-wrapper ${view}`}>
          <>
            {AstraDeviceConfig.DEVICES_TRACKING.map((device) => (
              <Device {...device} key={device.id * 11}/>
            ))}
            {AstraDeviceConfig.DEVICES_METRICS.map((device) => (
              <DeviceMetrics {...device} key={device.id * 11} display={display} setDisplay={setDisplay}/>
            ))}
          </>
          <div className="under-text">
            <img src={footer} alt="Klika Tech"/>
          </div>
          {view === "full" && display !== "full-device-metric" && (
            <div
              className="grid-view"
              onClick={() => {
                dispatch(setViewGrid());
              }}
            >
              <img src={gridIcon}/>
              <span>Grid View</span>
            </div>
          )}
        </div>
      </>
    </Context.Provider>
  );
};

export default Main;
