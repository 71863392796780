/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:f068db7c-d44c-4ba8-a0b4-f2d56ba7b948",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_5OqtnpM4g",
    "aws_user_pools_web_client_id": "10tj06iocqrtprun9n834sser0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "eu-central-1",
            "search_indices": {
                "items": [
                    "demo-astra2024-default"
                ],
                "default": "demo-astra2024-default"
            },
            "maps": {
                "items": {
                    "demo-astra2024-default": {
                        "style": "VectorHereExplore"
                    }
                },
                "default": "demo-astra2024-default"
            }
        }
    }
};


export default awsmobile;
