import Plot from 'react-plotly.js';
import {Data, Datum} from "plotly.js";

type Props = {
  id: number;
  x?: number;
  y?: number;
  z?: number;
  isFullMode?: boolean;
};
const DiagramAccelerometer = ({id, x, y, z, isFullMode}: Props) => {
  return (
    <Plot className={isFullMode ? "grafana-panel-full" : "grafana-panel"}
          config={{
            displayModeBar: false,
            showLink: false
          }}
          key={id}
          data={[
            {
              showlegend: false,
              showscale: false,
              x: [0], y: [0], z: [0],
              u: [x as Datum], v: [y as Datum], w: [z as Datum],
              hoverinfo: "u+v+w",
              type: 'cone',
              colorscale: [
                [0, 'rgb(46, 93, 185)'],
                [1, 'rgb(46, 93, 185)']
              ]
            } as unknown as Data
          ]}
          layout={
            {
              autosize: true,
              margin:{
                l:0,
                r:0,
                b:0,
                t:0,
                pad:0
              },
              scene: {
                aspectmode: "cube",
                aspectratio: {
                  x: 0.5, y: 1, z: 0.5
                },
                xaxis:{
                  dtick: 1,
                  range: [-10, 10],
                  fixedrange: true
                },
                yaxis:{
                  dtick: 1,
                  range: [-10, 10],
                  fixedrange: true
                },
                zaxis:{
                  dtick: 1,
                  range: [-10, 10],
                  fixedrange: true
                },
                camera: {
                  eye: {
                    x: 0.5, y: 1, z: 0.5
                  }
                }
              }
            }
          }
    />
  )
}

export default DiagramAccelerometer;