import {useContext, useEffect, useRef, useState} from "react";
import {normalizeEvent} from "../normalizers/EventNormalizer";
import names from "../events/names.js";
import {IEvent, IMainEvent} from "../types/Event";
import unknownEvent from "../events/unknown.json";
import {AWSIoTProvider, PubSub} from "@aws-amplify/pubsub";
import getAddress from "../services/location";
import {useQuery} from "../hooks/useQuery";
import {DEF_EVENT_TYPE} from "../constants";
import moment from "moment";
import Events from "./Events";
import Map from "./Map";
import Context from "../store/context";
import {LatLng} from "../types/LatLng";
import {AstraAwsConfig} from "../AstraConfig"
import {setCoordinatesCache} from "../store/actions";

const sleep = async (time: number) => {
    PubSub.removePluggable("AWSIoTProvider");
    PubSub.addPluggable(
        new AWSIoTProvider({
            aws_pubsub_region: AstraAwsConfig.REGION,
            aws_pubsub_endpoint:
            AstraAwsConfig.AWS_IOT_WS_ENDPOINT,
        })
    );
    return setTimeout(() => {
        return null;
    }, time);
};

const startAt = new Date();
const infoPosition = (window.innerWidth - 890) / 2;

type Props = {
    id: number;
    name: string;
    topic: string;
    center: LatLng;
    isMock: boolean; // is a Mocked device (hardcoded routes)
    route: Array<Array<number>>;
};

const Device = ({id, name, center, topic, route, isMock}: Props) => {
    let i = useRef(1);
    const routeLenght = route.length;

    const [event, setEvent] = useState<IEvent | null>(null);
    const [coordinates, setCoordinates] = useState<Array<LatLng>>([]);
    const [coordinatesAws, setCoordinatesAws] = useState<Array<LatLng>>([]);
    const [events, setEvents] = useState<Array<IEvent>>([]);
    const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
    const [isSubOpen, setIsSubOpen] = useState<boolean>(true);
    const {state, dispatch} = useContext(Context);
    const {view, selectedDevice, coordinatesCache} = state;
    const selected = selectedDevice === name;
    const isOff = useQuery(id.toString());
    const [toggledMap, toggle] = useState<string>("gps")

    const calculateTimeDiff = () => {
        const now = new Date();
        const diffInHours = moment(now).diff(startAt, "hours");
        const diffInMinutes =
            moment(now).diff(startAt, "minutes") - diffInHours * 60;
        return `${diffInHours} hrs ${diffInMinutes} minutes`;
    };

    const getAddressThroughCache = async (latLng: LatLng) => {
        // Read address through cache to reduce the number of calls from client
        const latStr = latLng.lat;
        const lngStr = latLng.lng;
        const mapKey = `${latStr}-${lngStr}`;
        if(mapKey in coordinatesCache) return coordinatesCache[mapKey];
        const address = await getAddress({
            lat: Number(latStr),
            lng: Number(lngStr)
        } as LatLng);
        const newCache = {...coordinatesCache, [mapKey]: address}
        dispatch(setCoordinatesCache(newCache));
        return address;
    }

    const setEventWithAddress = async (e: IEvent) => {
        try {
            const address = await getAddressThroughCache(e.position);
            setEvents((prevState) => {
                // TODO: The logic below caused the issue when different events with equal latitude are not displayed
                // in the events list. It is commented as a quick fix for the issue, but further investigation is required.
                // if (toggledMap === "gps" && prevState[0]?.position.lat === e.position.lat) return prevState;
                return [
                    {
                        ...e,
                        key: prevState.length + 1,
                        date: moment.unix(e.timestamp).format("MMMM DD, hh:mm:ss"),
                        address: address
                    },
                    ...prevState,
                ];
            });
        } catch (error) {
            console.error(error);
        }
    };

    const emitEvent = (e: IMainEvent | null) => {
        let eventToSet;
        if (isMock) {
            if (!e || !isOff) {
                if (i.current === routeLenght) i.current = 0;
                const currentEvent = e
                    ? e
                    : {...unknownEvent, msg_type: DEF_EVENT_TYPE};
                eventToSet = normalizeEvent({
                    ...currentEvent,
                    coordinates: [...route[i.current]],
                    // coordinatesAws: [e.],// TODO change route[i.current] with the real data
                });
                i.current++;
            } else {
                eventToSet = normalizeEvent(e);
            }
            setEvent(eventToSet);
        }
        if (!e?.coordinates || !e?.coordinates[0] || !e?.coordinates[1]) return null;
        if (!e?.coordinatesAws || !e?.coordinatesAws[0] || !e?.coordinatesAws[1]) return null; // TODO check the condition. if the coordinates and coordinatesAws are null
        if (e) {
            eventToSet = normalizeEvent(e);
            setEvent(eventToSet);
        }
    };

    useEffect(() => {
        if (!isOff) setInterval(emitEvent, 1000);
    }, []);

    useEffect(() => {
        if (event) {
            if (event.type !== DEF_EVENT_TYPE) {
                setEventWithAddress(event);
            }

            setCoordinates([...coordinates, event.position]);
        }
    }, [event]);

    const resubscribe = async () => {
        sleep(5000);
        setIsSubOpen(false);
    };

    useEffect(() => {
        let sub = PubSub.subscribe(topic).subscribe({
            next: (data) => {
                const value = data.value;
                emitEvent((value as any).payload ? (value as any).payload.value : value);
            },
            error: (error) => {
                console.error(error);
                if (window.navigator.onLine) resubscribe();
            },
            complete: () => console.log("Done"),
        });
        return () => {
            sub.unsubscribe();
        };
    }, [window.navigator.onLine, isSubOpen]);

    const callback = () => {
        toggle(toggledMap === "gps" ? "aws" : "gps");
    };

    return (
        <div
            className={`base-container ${view} ${
                view === "full" && !selected ? "none" : ""
            }`}
        >
            {view === "full" && selected && (
                <div className="map-info" style={{left: infoPosition}}>
                    <p className="title">
                        Session Start:{" "}
                        <span>{moment(startAt).format("MMMM DD, hh:mm")}</span>
                        <span> Local Time</span>
                    </p>
                    <span className="border"></span>
                    <p className="title">
                        Total Time: <span>{calculateTimeDiff()}</span>
                    </p>
                    <span className="border"></span>
                    <p className="title">
                        Total Events: <span>{events.length}</span>
                    </p>
                    <span className="border"></span>
                    <p className="title bold">GPS</p>
                    {/*<label className="toggle">
                        <input className="toggle-input" type="checkbox" defaultChecked={toggledMap === "aws"}
                               onClick={callback}/>
                        <span className="toggle-span"></span>
                    </label>
                    <p className="title bold">AWS Device Location</p>*/}
                </div>
            )}

            <Events
                name={name}
                events={events}
                selectedEvent={selectedEvent}
                selected={selected}
                toggledMap={toggledMap}
            />
            <Map
                center={center}
                event={event}
                events={events}
                coordinates={coordinates}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                selected={selected}
                name={name}
                toggledMap={toggledMap}
            />
        </div>
    );
};

export default Device;
