export default [
  {
    key: "acc_crash",
    name: "Crash Alert",
  },
  {
    key: "acc_flip",
    name: "Flip Alert",
  },
  {
    key: "acc_mot_start",
    name: "Motion Start",
  },
  {
    key: "acc_mot_end",
    name: "Motion End",
  },
  {
    key: "acc_mot_less",
    name: "Motionless",
  },
  {
    key: "acc_shock",
    name: "Shock Alert",
  },
  {
    key: "b_press_once",
    name: "Button Pressed Once",
  },
  {
    key: "batt_low",
    name: "Battery Low Alert",
  },
  {
    key: "h_high",
    name: "Humidity High Alert",
  },
  {
    key: "h_low",
    name: "Humidity Low Alert",
  },
  {
    key: "h_surge",
    name: "Humidity Surge Alert",
  },
  {
    key: "l_thr",
    name: "Light Alert",
  },
  {
    key: "mode_changed",
    name: "Mode Changed",
  },
  {
    key: "regular",
    name: "Heartbeat",
  },
  {
    key: "rssi_low",
    name: "RSSI Low Alert",
  },
  {
    key: "power_up",
    name: "Power Up",
  },
  {
    key: "recovery_parking",
    name: "Recovery Parking",
  },
  {
    key: "recovery_driving",
    name: "Recovery Driving",
  },
  {
    key: "sos",
    name: "SOS Alert",
  },
  {
    key: "status",
    name: "Status",
  },
  {
    key: "t_high",
    name: "Temperature High Alert",
  },
  {
    key: "t_low",
    name: "Temperature Low Alert",
  },
  {
    key: "t_surge",
    name: "Temperature Surge Alert",
  },
  {
    key: "t_thr",
    name: "Temperature Alert",
  },
];
