import React, {useState} from "react";
import {ReactComponent as AccelerometerIcon} from "../images/accelerometer.svg";
import {ReactComponent as AirQualityIcon} from "../images/air-quality.svg";
import {ReactComponent as ColorLightIcon} from "../images/color-light.svg";
import {ReactComponent as TemperatureIcon} from "../images/temperature.svg";
import {ReactComponent as HumidityIcon} from "../images/humidity.svg";
import {ReactComponent as AirPressureIcon} from "../images/air-pressure.svg";
import {Metrics} from "../types/Metrics";

type Props = {
  id: number;
  selected?: boolean;
  row?: boolean;
  setIframeSrc?: any;
  metrics: Metrics;
  measurements: any;
};
const MetricButtons = ({id, selected, row, setIframeSrc, measurements, metrics}: Props) => {
  const info = false;

  const [activeButton, setActiveButton] = useState("humidity");


  const handleClick = (name: string) => {
    // @ts-ignore
    setIframeSrc(measurements[name].src);
    setActiveButton(name);
  }

  const getUnitOfMeasure = (name: string) => {
    switch (name) {
      case "humidity":
        return "%"
      case "temperature":
        return "°C"
      case "airPressure":
        return " kPa"
      case "airQuality":
        return " AQI"
      case "colorLight":
        return " lx"
      default:
        return ""
    }
  }

  const getMeasurementIcon = (name: string) => {
    switch (name) {
      case "humidity":
        return <HumidityIcon key={`${id}-humidity-icon`}/>
      case "temperature":
        return <TemperatureIcon key={`${id}-temperature-icon`}/>
      case "airPressure":
        return <AirPressureIcon key={`${id}-air-pressure-icon`}/>
      case "accelerometer":
        return <AccelerometerIcon key={`${id}-accelerometer-icon`}/>
      case "airQuality":
        return <AirQualityIcon key={`${id}-air-quality-icon`}/>
      case "colorLight":
        return <ColorLightIcon key={`${id}-color-light-icon`}/>
      default:
        return null
    }
  }

  const generateMetricRecord = (name: string) => {
    if(name === "accelerometer" && metrics["accelerometer"] )
      return `${metrics.accelerometer[0]}, ${metrics.accelerometer[1]}, ${metrics.accelerometer[2]}`
    return metrics[name as keyof Metrics] ? metrics[name as keyof Metrics] + getUnitOfMeasure(name) :
      <div className="dot-flashing"/>
  }

  return (
    <>
      {Object.keys(measurements).filter(it => it != "logs").map((measurement) => (
        <div key={`${id}-metric-button-${measurement}`} className="prevent-select">
          <div className={`event-container metrics-button 
                        ${row ? "row" : ""} 
                        ${info ? "info" : ""} 
                        ${selected ? "active" : ""}
                        ${activeButton === measurement ? "selected" : ""}
         
              `}
            onClick={() => handleClick(measurement)}
          >
            <div className="icon">
              {getMeasurementIcon(measurement)}
            </div>
            <div>
              <div className={`title ${info ? "" : "info"}`}>
                {measurements[measurement].title}
              </div>
              <div className="data">{generateMetricRecord(measurement)}</div>
            </div>
          </div>
        </div>
      ))}
    </>

  )
}

export default MetricButtons;