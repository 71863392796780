import { useContext, useState } from "react";
import alerts from "../events/alerts.js";
import ReactJson from "@microlink/react-json-view";
import { setPopupEvent } from "../store/actions";
import Context from "../store/context";
import { ReactComponent as XIcon } from "../images/x-icon.svg";
import warningIcon from "../images/warning.svg";
import { revertEvent } from "../normalizers/EventNormalizer";
import moment from "moment";

const Popup = () => {
  const { state, dispatch } = useContext(Context);
  const { popupEvent } = state;
  const [json, setJson] = useState<boolean>(false);

  let date = moment(new Date()).format("dddd, MMMM Do HH:mm:ss")
  if(popupEvent && popupEvent.timestamp) {
    date = moment.unix(popupEvent.timestamp).format("dddd, MMMM Do HH:mm:ss");
  }

  return popupEvent ? (
    <>
      <div className="popup">
        <XIcon
          className="x-icon"
          onClick={() => dispatch(setPopupEvent(null))}
        />
        <div className="popup__header">
          <p className="title">Message ID: {popupEvent.msg_number}</p>
          <p>{date}</p>
        </div>
        <div className="switcher">
          <p onClick={() => setJson(false)}>INFO DATA</p>
          <p onClick={() => setJson(true)}>JSON</p>
          <div className={`bottom-line ${json ? "json" : "info"}`}></div>
        </div>
        <div className="popup__body">
          {json ? (
            <ReactJson
              src={revertEvent(popupEvent)}
              theme="summerfruit:inverted"
              indentWidth={4}
              style={{ display: "flex", alignItems: "" }}
              enableClipboard={false}
            />
          ) : (
            <>
              <div className="event-title">
                <p style={(alerts.indexOf(popupEvent.msg_type) >= 0) ? {color: "#FF000D"} : {color: "#0064DC"}}>
                  {popupEvent.key}. <b>{popupEvent.name}</b>
                </p>
                {
                    alerts.indexOf(popupEvent.msg_type) >= 0 ?
                    <img src={warningIcon} alt="Warning" /> :
                    <></>
                }
              </div>
              <div className="event-info">
                <div>
                  <p className="title">Location:</p>
                  <p>{popupEvent.address}</p>
                </div>
                <div>
                  <p className="title">Accelerometer:</p>
                  <p>
                    {"accel" in popupEvent ? popupEvent.accel.map((accel, index) => (
                      <span key={index}>{accel}</span>
                    )) : "N/A"}
                  </p>
                </div>
                <div>
                  <p className="title">Temperature:</p>
                  <p>{"temp" in popupEvent  ? popupEvent.temp + "°C": "N/A"}</p>
                </div>
                <div>
                  <p className="title">Threshold:</p>
                  <p>{popupEvent.temp_threshold}°C</p>
                </div>
                <div>
                  <p className="title">Battery:</p>
                  <p>{"batt" in popupEvent ? popupEvent.batt + "%": "N/A"}</p>
                </div>
                <div>
                  <p className="title">{"humidity" in popupEvent  ? "Humidity:" : "Light:"}</p>
                  <p>{"humidity" in popupEvent  ? popupEvent.humidity + "%" : ("light" in popupEvent ? popupEvent.light + "%" : "N/A")}</p>
                </div>
                <div>
                  <p className="title">Threshold:</p>
                  <p>{"humidity_threshold" in popupEvent  ? popupEvent.humidity_threshold + "%" : ("light_threshold" in popupEvent ? popupEvent.light_threshold + "%" : "N/A")}</p>
                </div>
                <div>
                  <p className="title">RSSI:</p>
                  <p>{popupEvent.rssi}</p>
                </div>
                <div>
                  <p className="title">Firmware:</p>
                  <p>v.{popupEvent.firmware}</p>
                </div>
                <div>
                  <p className="title">Model:</p>
                  <p>{popupEvent.device_model}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="empty-space"
        onClick={() => dispatch(setPopupEvent(null))}
      ></div>
    </>
  ) : (
    <></>
  );
};

export default Popup;
