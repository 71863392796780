import {MapView} from '@aws-amplify/ui-react';
import {IEvent} from "../types/Event";
import {useContext, useEffect, useRef, useState} from "react";
import alerts from "../events/alerts.js";
import Context from "../store/context";
import eventHoveredIcon from "../images/event-hover.svg";
import redEventIcon from "../images/red-event.svg";
import eventIcon from "../images/event.svg";
import circleIcon from "../images/circle.svg";
import {setPopupEvent} from "../store/actions";
import Event from "./Event";
import {LatLng} from "../types/LatLng";
import {FullscreenControl, Layer, Marker, NavigationControl, Popup, ScaleControl, Source, useMap} from 'react-map-gl';
import {AstraAwsConfig} from "../AstraConfig";
import {ReactComponent as MarkerIcon} from "../images/marker.svg";

type Props = {
  event: IEvent | null;
  events: Array<IEvent>;
  coordinates: Array<LatLng>;
  selectedEvent: IEvent | null;
  setSelectedEvent: (event: IEvent | null) => void;
  center: LatLng;
  selected?: boolean;
  name: string;
  toggledMap:string;
};

type NavigationButtonProps = {
  position: LatLng;
};

const NavigationButton = ({position}: NavigationButtonProps) => {
  const {current: map} = useMap();
    map && map.panTo([position.lng, position.lat]);

  const onClick = () => {
    map && map.flyTo({center: [position.lng, position.lat]});
  };

  return (
    <button className="navigation-button" onClick={onClick}>
    </button>
  );
};

type MarkerType = {
  event: IEvent | null;
  toggledMap:string;
}
const MarkerComponent = ({event, toggledMap}: MarkerType) => {
  const {current: map} = useMap();
  const long = event?.position.lng
  const latitude = event?.position.lat
  const [lng, setLng] = useState(long)
  const [lat, setLat] = useState(latitude)
  const prevLng = useRef(lng);
  const prevLat = useRef(lat);

  useEffect(() => {
    prevLng.current = lng;
    prevLat.current = lat;
    setLng(long)
    setLat(latitude)
  }, [long, latitude]);

  const rotationMarker = () => {
    const mapBearing = map?.getBearing()
    // @ts-ignore
    const dLng = lng - prevLng.current ;
    // @ts-ignore
    const dLat = lat - prevLat.current;
    // @ts-ignore
    const rotation =  147.144 + (Math.atan2(dLng, dLat) * 180 / Math.PI); //angle //147.144 because the marker image is rotated by 147.144
    // @ts-ignore
    return mapBearing < 0 ? Math.abs(mapBearing)  + rotation :rotation - mapBearing;
  }

  return (
    <Marker
      key={"cir"}
      latitude={lat}
      longitude={lng}
      style={{zIndex: 10}}
      rotation={rotationMarker()}
    >
      <img src={circleIcon}/>
    </Marker>
  );
}

const Map = ({
               event,
               events,
               coordinates,
               selectedEvent,
               setSelectedEvent,
               center,
               selected,
               name,
               toggledMap,
             }: Props) => {

  const isLoaded = true;

  const [key, setKey] = useState<string>("def");
  const [keyCircle, setKeyCircle] = useState<string>("cir");
  const [keyLine, setKeyLine] = useState<string>("line");
  const {state, dispatch} = useContext(Context);
  const {view} = state;

  const mapStyle = () => {
    if (view !== "full") return {width: "95%", height: "92.3%"};
    return selected ? {width: "100%", height: "96.2%"} : {width: "0", height: "0"};
  }

  useEffect(() => {
    if (key === "def" && isLoaded) setKey("default");
    if (keyLine === "line" && isLoaded) setKeyLine("polyline");
    if (keyCircle === "cir" && isLoaded) setKeyCircle("circle");
  }, [isLoaded]);

  const toggleShow = (e: IEvent) => {
    setSelectedEvent(e);
  };

  const toggleClose = () => {
    setSelectedEvent(null);
    document.querySelector("gm-style-iw gm-style-iw-c")?.remove();
  };

  const getEventIcon = (e: IEvent) => {
    if (selectedEvent && selectedEvent.key === e.key) return eventHoveredIcon;
    if (alerts.indexOf(e.msg_type) >= 0) return redEventIcon;
    return eventIcon;
  };

  const getPointZindex = (e: IEvent) => {
    if (alerts.indexOf(e.msg_type) >= 0) return 2;
    return 1;
  };

  const mapContainerStyle = () => {
    if (view !== "full") return {position: 'relative' as 'relative'};
    return selected ? {position: 'absolute' as 'absolute'} : {position: 'absolute' as 'absolute', width: "0", height: "0"};
  }

  return (
    <div className="map-outer-container" style={mapContainerStyle()}>
      <MapView
        mapStyle={AstraAwsConfig.MAPS.default}
        initialViewState={{
          latitude: center.lat,
          longitude: center.lng,
          zoom: 13,
        }}
        style={mapStyle()}
        onIdle={(event) => event.target.resize()}
        attributionControl={false}
      >
        <FullscreenControl position="top-right"/>
        <NavigationControl position="top-right"
                           visualizePitch={true}/>
        <ScaleControl position="bottom-right"/>
        {event && toggledMap ==="gps" &&
          <>
            <MarkerComponent event={event} toggledMap={toggledMap}/>
          </>
        }
        {events.map((e) => (
          <Marker
            key={e.key}
            latitude={e.position.lat}
            longitude={e.position.lng}
            style={{zIndex: getPointZindex(e)}}
          >
            <img
              src={getEventIcon(e)}
              onMouseOver={() => toggleShow(e)}
              onMouseOut={toggleClose}
              onClick={() => {
                dispatch(setPopupEvent({...e, device: name, address: e.address}));
              }}
            />
          </Marker>
        ))}
        {selectedEvent && (
          <Popup
            longitude={selectedEvent.position.lng}
            latitude={selectedEvent.position.lat}
            style={{zIndex: 40}}
            closeButton={false}
          >
            <Event event={selectedEvent} info name={name} toggledMap={toggledMap}/>
          </Popup>
        )}
        {toggledMap ==="gps" && (
          <Source id="polylineLayer" type="geojson" data={
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: coordinates.map(c => [c.lng, c.lat])
              }
            }}>
            <Layer
              key={keyLine}
              id={keyLine}
              type='line'
              paint={{
                'line-color': toggledMap ==="gps" ? '#EB5D19' : "#00C853",
                'line-width': 3
              }}
            />
          </Source>
        )}
        <NavigationButton position={event ?(event.position) : center}/>
      </MapView>
    </div>
  );
};
export default Map;