import {LatLng} from "../types/LatLng";
import {Geo} from "aws-amplify";
import {AstraAwsConfig} from "../AstraConfig";
import {Place} from "@aws-amplify/geo/lib-esm/types/Geo";

const generateAddressString = (place: Place) => {
    const result = [
        (place.street ? place.street + " " : "") + (place.addressNumber ?? ""),
        (place.postalCode ? place.postalCode + " " : "") + (place.municipality ?? "")
    ].join(", ");
    return result;
}

const getAddress = async (location: LatLng) => {
    try {
        Geo.configure({
            region: AstraAwsConfig.REGION,
            search_indices: {
                items: [AstraAwsConfig.LOCATION_SEARCH_INDEX],
                default: AstraAwsConfig.LOCATION_SEARCH_INDEX
            }
        })

        const res = await Geo.searchByCoordinates([location.lng, location.lat])
        const result = (res && res.label) ? generateAddressString(res) : null;
        return result;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default getAddress;
