import {useContext, useEffect, useState} from "react";
import Context from "../store/context";
import DeviceMetricsMenu from "./DeviceMetricsMenu";
import DeviceMetricFull from "./DeviceMetricFull";
import {Measurements} from "../types/MeasurementGrafana";
import {AWSIoTProvider, PubSub} from "@aws-amplify/pubsub";
import {AstraAwsConfig} from "../AstraConfig";
import {IMainEvent} from "../types/Event";
import {Metrics} from "../types/Metrics";
import DiagramAccelerometer from "./DiagramAccelerometer";

const sleep = async (time: number) => {
  PubSub.removePluggable("AWSIoTProvider");
  PubSub.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: AstraAwsConfig.REGION,
      aws_pubsub_endpoint:
      AstraAwsConfig.AWS_IOT_WS_ENDPOINT,
    })
  );
  return setTimeout(() => {
    return null;
  }, time);
};

type Props = {
  id: number;
  name: string;
  topic: string;
  measurements: Measurements;
  setDisplay: any;
  display: string;
};

const DeviceMetrics = ({id, name, topic, measurements, display, setDisplay}: Props) => {

  const {state} = useContext(Context);
  const {view, selectedDevice} = state;
  const selected = selectedDevice === name;
  const [iframeSrc, setIframeSrc] = useState<string>(measurements.humidity.src);
  const [isSubOpen, setIsSubOpen] = useState<boolean>(true);
  const [metrics, setMetrics] = useState({} as Metrics);

  const resubscribe = async () => {
    sleep(5000);
    setIsSubOpen(false);
  };

  const emitEvent = (e: IMainEvent | null) => {
    if (e) {
      const newMetrics = metrics;
      newMetrics.deviceId = id;
      if (e?.humidity) newMetrics.humidity = e.humidity;
      if (e?.temp) newMetrics.temperature = e.temp;
      if (e?.air_pressure) newMetrics.airPressure = e.air_pressure;
      if (e?.air_quality) newMetrics.airQuality = e.air_quality;
      if (e?.light) newMetrics.colorLight = e.light;
      if (e?.accel) newMetrics.accelerometer = e.accel;
      setMetrics({...newMetrics});
    }
  };

  useEffect(() => {
    setInterval(emitEvent, 1000);
  }, []);

  useEffect(() => {
    let sub = PubSub.subscribe(topic).subscribe({
      next: (data) => {
        emitEvent((data as any).value);
      },
      error: (error) => {
        console.error(error);
        if (window.navigator.onLine) resubscribe();
      },
      complete: () => console.log("Done"),
    });
    return () => {
      sub.unsubscribe();
    };
  }, [window.navigator.onLine, isSubOpen]);


  return (
    <>
      {view === "full" && selected && display === "full-device-metric" ?
        <>
          <DeviceMetricFull name={name}
                            id={id}
                            key={`${id}-device-metrics-full`}
                            metrics={metrics}
                            setDisplay={setDisplay}
                            measurements={measurements}
          />
        </>
        : <>{view === "full" && !selected ? <></> :
            <>
                <div
                className={`base-container ${view} ${
                    view === "full" && !selected ? "none" : ""
                }`}
                >
                    <DeviceMetricsMenu
                        key={`${id}-device-metrics-menu`}
                        id={id}
                        name={name}
                        metrics={metrics}
                        selected={selected}
                        setDisplay={setDisplay}
                        setIframeSrc={setIframeSrc}
                        measurements={measurements}
                    />
                    <div className="map-outer-container" style={{position: "relative"}}>
                        {
                        iframeSrc === measurements.accelerometer.src ?
                            <DiagramAccelerometer key={`${id}-device-metrics-menu`}
                                                id={id}
                                                x={metrics.accelerometer ? metrics.accelerometer[0] : 0}
                                                y={metrics.accelerometer ? metrics.accelerometer[1] : 0}
                                                z={metrics.accelerometer ? metrics.accelerometer[2] : 0}
                            /> : <iframe className="grafana-panel" src={iframeSrc}/>
                        }
                    </div>
                </div>
            </>
        }</>
      }
    </>
  )
    ;
};

export default DeviceMetrics;
