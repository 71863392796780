import { IEvent } from "../types/Event";
import {SET_COORDINATES_CACHE, SET_POPUP_EVENT, SET_VIEW_FULL, SET_VIEW_GRID} from "./actions-types";

export const setPopupEvent = (data: IEvent | null) => {
  return {
    type: SET_POPUP_EVENT,
    payload: data,
  };
};

export const setViewGrid = () => {
  return {
    type: SET_VIEW_GRID,
  };
};

export const setViewFull = (device: string) => {
  return {
    type: SET_VIEW_FULL,
    payload: device,
  };
};

export const setCoordinatesCache = (coordinatesCache: Object) => {
  return {
    type: SET_COORDINATES_CACHE,
    payload: coordinatesCache,
  };
};