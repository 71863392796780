import React, {useContext, useState, useEffect, useRef} from 'react'
import {ReactComponent as GridViewIcon} from "../images/grid-view.svg";
import {ReactComponent as MarkerIcon} from "../images/marker.svg";
import MetricButtons from "./MetricButton";
import {AstraAwsConfig} from "../AstraConfig";
import {MapView} from "@aws-amplify/ui-react";
import {setViewGrid} from "../store/actions";
import Context from "../store/context";
import {Measurements} from "../types/MeasurementGrafana";
import {Metrics} from "../types/Metrics";
import {Marker, useMap} from "react-map-gl";
import DiagramAccelerometer from "./DiagramAccelerometer";

type Props = {
  id: number;
  name: string;
  metrics: Metrics;
  setDisplay: any;
  measurements: Measurements;
};
type PanProps = {
  newCenter: Array<number>;
  newAngle: number;
}

let lat = 49.452934;
let lng = 11.078034;
let angle = 0;

const Pan = ({newCenter, newAngle}: PanProps) => {
  const {current: map} = useMap();


  // useEffect((),)
  // @ts-ignore
  map && map.easeTo({
    bearing: newAngle * 360 / (2 * Math.PI),
    center: [lng, lat],
    // duration: 2000,
    easing: x => x
  });

  // map && map.setBearing(newAngle * 360 / (2 * Math.PI))
  // @ts-ignore
  // map && map.panTo(newCenter);
  // map && map.rotateTo(newAngle * 360 / (2 * Math.PI)) && map.panTo(newCenter);
  const onClick = () => {
    // @ts-ignore
    map && map.panTo(newCenter);
  };

  return (
    <button className="navigation-button" onClick={onClick}>
    </button>
  );
}


const DeviceMetricFull = ({id, name, metrics, setDisplay, measurements}: Props) => {
  const {dispatch} = useContext(Context);
  const [iframeSrc, setIframeSrc] = useState<string>(measurements.humidity.src); // TODO change depends on the selected measure
  const center = {lat: 49.452934, lng: 11.078034}

  const mapStyle = () => {
    return {width: '96.3%', height: "89.5%"};
  }
  const handleGridViewClick = () => {
    setDisplay('grid')
    dispatch(setViewGrid())
  }

  const bearing = () => {
    const accel = metrics.accelerometer;
    let angle = 0;
    if (accel) {
      angle = Math.atan2(accel[1], accel[0]);

      angle = angle * (180 / Math.PI)
      angle = angle + 90
      angle = (angle + 360) % 360
    }
    return angle;
  }
  const calc = (a: number) => {
    return (Math.pow(Math.max(Math.abs(a) - 1.0, 0), 2) / 81) * Math.sign(a) / 10000; // k = 10000
  }
  // const [accel, setAccel] = useState([0, 0, 0]);

  // let accel: any[] = [0, 0, 1]
  // @ts-ignore
  const accel = metrics.accelerometer;
  const x = () => {
    return calc(accel ? accel[0] : 0);
  }
  const y = () => {
    return accel ? accel[1] : 0;
  }
  // const newCenter = [x(), y()];
  const angleCalc = () => {
    const yVal = y();
    angle += (Math.pow(Math.max(Math.abs(yVal) - 1.0, 0), 2) / 81) * (Math.sign(yVal) / 3); // k = 10

    if (angle < 0) {
      angle += 2 * Math.PI;
    }

    if (angle > 2 * Math.PI) {
      angle -= 2 * Math.PI;
    }
    console.log("angle= ", angle)
    return angle
  }

  const xx = () => {
    const val = x() * Math.sin(angle);
    lng += val;
    console.log("xx = ", lng);
    console.log("X val = ", val);
    return lng;
  }

  const xy = () => {
    const val = x() * Math.cos(angle);
    lat += val;
    console.log("xy = ", lat);
    console.log("Y val = ", val);

    return lat;
  }
  // x_X= x * cos(angle_new);
  // x_Y = x * sin(angle_new);
  // lat = lat + f(x_X);
  // lng = lng + f(x_Y);
  const newCenter = [xx(), xy()];

  let newAngle = angleCalc();

  return (
    <div className="metric-full">
      <div className="header">
        <p className="title">{name}</p>
        <div className="grid-view-button" onClick={() => handleGridViewClick()}>
          <GridViewIcon/>
          <span className="button-title">Grid View</span>
        </div>
      </div>
      <div className="buttons">
        {/*{metrics.map((metric) => (*/}
        <>
          <MetricButtons setIframeSrc={setIframeSrc}
                         id={id}
                         key={`${id}-metric-buttons-full`}
                         row={true}
                         metrics={metrics}
                         measurements={measurements}/>
        </>
        {/*))}*/}
      </div>
      <div className="main">
        <div className="info">
          <div className="graphic">
            <h4 className="title">Value</h4>
            {
              iframeSrc === measurements.accelerometer.src ?
                <DiagramAccelerometer key={`${id}-device-metrics-menu`}
                                      id={id}
                                      isFullMode={true}
                                      x={metrics.accelerometer ? metrics.accelerometer[0] : 0}
                                      y={metrics.accelerometer ? metrics.accelerometer[1] : 0}
                                      z={metrics.accelerometer ? metrics.accelerometer[2] : 0}
                /> : <iframe className="grafana-panel-full" src={iframeSrc}/>
            }
          </div>
          <div className="graphic">
            <h4 className="title">History Log</h4>
            <iframe className="grafana-panel-full"
                    src={measurements.logs.src}/>
          </div>
        </div>
        <div className="device-map">
          <h4 className="title">Asset Location 3D view</h4>
          <MapView
            mapStyle={AstraAwsConfig.MAPS.astra3d}
            initialViewState={{
              latitude: lat,
              longitude: lng,
              zoom: 18,
              bearing: 0,
            }}
            // bearing={bearing()}
            style={mapStyle()}
            onIdle={(event) => event.target.resize()}
            attributionControl={false}
          >

            <Marker
              key={"def"}
              latitude={lat}
              longitude={lng}
              style={{zIndex: 30}}
              rotation={bearing()}//bearing()
              rotationAlignment={"map"}
            >
              <MarkerIcon/>
            </Marker>
            <Pan newCenter={newCenter} newAngle={newAngle}/>
          </MapView>
        </div>

      </div>
    </div>
  )
}

export default DeviceMetricFull