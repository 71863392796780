import {ReactComponent as TwoSideArrowIcon} from "../images/two-side-arrow.svg";
import {useContext} from "react";
import Context from "../store/context";
import {setViewFull} from "../store/actions";
import {Measurements} from "../types/MeasurementGrafana";
import MetricButtons from "./MetricButton";
import {Metrics} from "../types/Metrics";

type EventsProps = {
  id: number;
  name: string;
  metrics: Metrics;
  selected?: boolean;
  setDisplay: any;
  setIframeSrc: any;
  measurements: Measurements;
};

const Events = ({id, name, metrics, selected, setDisplay, setIframeSrc, measurements}: EventsProps) => {
  const {state, dispatch} = useContext(Context);
  const {view} = state;

  return (
    <div className={`actions ${view}`}>
      <div className="header">
        <p className="title">{name}</p>
        {view === "grid" && (
          <TwoSideArrowIcon
            className="icon"
            onClick={() => {
              setDisplay('full-device-metric')
              dispatch(setViewFull(name))
            }}
          />
        )}

      </div>
      <div className="list metrics-buttons">
        <MetricButtons key={`${id}-metric-buttons`}
                       id={id}
                       selected={selected}
                       setIframeSrc={setIframeSrc}
                       metrics={metrics}
                       measurements={measurements}/>
      </div>
    </div>
  );
};

export default Events;
