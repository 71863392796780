import { useContext } from "react";
import { setPopupEvent } from "../store/actions";
import Context from "../store/context";
import { IEvent } from "../types/Event";
import alerts from "../events/alerts";
import moment from "moment/moment";

type EventProps = {
  event: IEvent;
  info?: boolean;
  selected?: boolean;
  name: string;
  toggledMap: string;
};

const Event = ({ event, info, selected, name, toggledMap }: EventProps) => {
  const { dispatch } = useContext(Context);
  let date = moment(new Date()).format("dddd, MMMM Do HH:mm:ss")
  if(event && event.timestamp) {
    date = moment.unix(event.timestamp).format("dddd, MMMM Do HH:mm:ss");
  }
  const address = event.address;
  return (
    <div
      className={`event-container ${info ? "info" : ""} ${
        selected ? "active" : ""
      }`}
      onClick={() => dispatch(setPopupEvent({ ...event, device: name, address: address }))}
    >
      <div className={`title ${info ? "" : "info"}`}>
        <p style={(alerts.indexOf(event.msg_type) >= 0) ? {color: "#FF000D"} : {color: "#0064DC"}}>
            <span className="key">{event.key}. <b>{event.name}</b></span>
        </p>
        {info && (
          <p>
            Current: {event.temp}°C; Threshold: {event.temp_threshold}°C
          </p>
        )}
      </div>

      <p className="data">{date}</p>
      {address && <p className="data">{address}</p>}
    </div>
  );
};

export default Event;
