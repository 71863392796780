import {IEvent} from "../types/Event";
import * as ACTION_TYPES from "./actions-types";
import geoCacheInitial from "../config/geoCache.json";

export interface IState {
    popupEvent: IEvent | null;
    view: "grid" | "full";
    selectedDevice: string;
    coordinatesCache: any;
}

export interface IAction {
    type: string;
    payload?: any;
}

export const initialState: IState = {
    popupEvent: null,
    view: "grid",
    selectedDevice: "",
    coordinatesCache: geoCacheInitial
};

export const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
        case ACTION_TYPES.SET_POPUP_EVENT:
            return {
                ...state,
                popupEvent: action.payload,
            };
        case ACTION_TYPES.SET_VIEW_GRID:
            return {
                ...state,
                view: "grid",
                selectedDevice: "",
            };
        case ACTION_TYPES.SET_VIEW_FULL:
            return {
                ...state,
                view: "full",
                selectedDevice: action.payload,
            };
        case ACTION_TYPES.SET_COORDINATES_CACHE:
            return {
                ...state,
                coordinatesCache: action.payload,
            };
        default:
            return state;
    }
};
