import { IEvent } from "../types/Event";
import Event from "./Event";
import { ReactComponent as TwoSideArrowIcon } from "../images/two-side-arrow.svg";
import { useContext } from "react";
import Context from "../store/context";
import { setViewFull } from "../store/actions";

type EventsProps = {
  name: string;
  events: Array<IEvent>;
  selectedEvent: IEvent | null;
  selected?: boolean;
  toggledMap:string;
};

const Events = ({ name, events, selectedEvent, selected, toggledMap }: EventsProps) => {
  const { state, dispatch } = useContext(Context);
  const { view } = state;

  return (
    <div className={`actions ${view}`}>
      <div className="header">
        <p className="title">{name}</p>
        {view === "grid" && (
          <TwoSideArrowIcon
            className="icon"
            onClick={() => dispatch(setViewFull(name))}
          />
        )}
      </div>
      <div className="list" style={{ height: (view === "full" && selected) ? "74vh" : "34vh" }}>
        {events.map((event) => (
          <Event
            key={event.key}
            event={event}
            selected={selectedEvent?.key === event.key}
            name={name}
            toggledMap={toggledMap}
          />
        ))}
      </div>
    </div>
  );
};

export default Events;
